<template>
  <TableView />
</template>

<script>
import TableView from '@/components/TableView.vue'

export default {
  name: 'NewEventContent',
  components: {
    TableView
  },

  data: () => ({
  }),
};
</script>